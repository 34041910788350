import React, {useContext, useMemo, useState} from 'react'
import styles from './cashback.module.css';
import {Button, Tabs, TabsContent, TabsList, TabsTrigger} from '../../components/atoms'
import cashbackTito from '../../assets/cashback tito.png'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {format, parseISO} from 'date-fns';
import {toast} from '../../hooks/useToast';
import {SiteContext} from '../../context/SiteContext';
import {BlockLoading, BlockTitle, ComboClient, DialogConfirm, EmptyState, ReactTable} from '../../components/blocks';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {LuList, LuShoppingCart} from 'react-icons/lu';
import {TbAlertCircleFilled} from 'react-icons/tb';

const CashBack = () => {

  const {fondo, printer, salaId} = useContext(SiteContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [activeTab, setActiveTab] = useState('productos');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const productUrl = 'https://wgt-tmp-uploads.s3.amazonaws.com/';
  const queryClient = useQueryClient();

  const {data: dataProducts, isLoading} = useQuery({
    queryKey: ['getProductsAvailable', selectedCustomer?.id],
    queryFn: async () => {
      const {data} = await axios.get(`${Apis.PC}/redeems/products-available?customerId=${selectedCustomer?.id}`);
      return data;
    },
    enabled: !!selectedCustomer?.id,
    staleTime: 0,
  });

  const {data} = useQuery({
    queryKey: ['getLastProducts', selectedCustomer?.id],
    queryFn: async () => {
      const {data} = await axios.get(`${Apis.PC}/redeems?customerId=${selectedCustomer?.id}`);
      return data;
    },
    enabled: !!selectedCustomer?.id,
    staleTime: 0,
  })

  /* Canjear producto */
  const cashbackRedeemMutation = useMutation({
    mutationFn: async () => {
      const param = {
        salaId: salaId,
        customerId: selectedCustomer?.id,
        productId: selectedProduct?.id,
        printerId: printer?.printerId,
      };
      await axios.post(Apis.PC + '/redeems', param);
    },
    onSuccess: () => {
      toast({
        title: 'Producto canjeado con éxito!',
        variant: 'success',
        duration: 2000,
      });
      queryClient.invalidateQueries(['getLastProducts']);
      setActiveTab('pendientes')
      setOpenConfirmDialog(false);
      setSelectedProduct(null);
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000,
      });
    },
  });

  const handleConfirmRedeem = () => {
    if (selectedProduct && selectedCustomer) {
      cashbackRedeemMutation.mutate();
    }
  };

  /* Eliminar producto */
  const deleteElement = (id) => axios.post(Apis.PC + '/redeems/' + id + '/cancel');

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_cashback', //mutateKey
    'getLastProducts', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  /* Confirmar canje de cashback */
  const redeemCashbackMutation = useMutation({
    mutationFn: async ({id}) => {
      await axios.post(Apis.PC + `/redeems/${id}/print-cashback`, {
        fondoId: fondo.id,
        computerId: printer?.computerId,
      });
    },
    onSuccess: () => {
      setSelectedProduct(null);
      setSelectedCustomer(null);
      setActiveTab('productos')
    },
  });

  const redeemCashbackModal = useConfirmDialog(
    '¿Deseas canjear este producto?',
    'op_redeem_cashback', // mutateKey
    '', // volver a llamar para refrescar la data
    ({id}) => redeemCashbackMutation.mutate({id})
  );

  /* Confirmar canje de producto */
  const redeemProductMutation = useMutation({
    mutationFn: async ({id}) => {
      await axios.post(Apis.PC + `/redeems/${id}/change-state?state=ENTREGADO`);
    },
    onSuccess: () => {
      setSelectedProduct(null);
      setSelectedCustomer(null);
      setActiveTab('productos')
    },
  });

  const redeemProductModal = useConfirmDialog(
    '¿Deseas canjear este producto?',
    'op_redeem_product', // mutateKey
    '', // volver a llamar para refrescar la data
    ({id}) => redeemProductMutation.mutate({id})
  );

  const getNameAndValue = (item) => {
    return item.couponTypeId !== null ? (item.exchangeValue + " CUPONES") : (item.exchangeValue + " PUNTOS")
  }

  const columns = useMemo(
    () => [
      {
        header: 'Producto',
        accessorKey: 'productName',
      },
      {
        header: 'Categoria',
        id: 'category',
        accessorFn: row => `${row.category ? row.category : '--'}`,
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName'
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Imagen',
        accessorKey: 'photoUrl',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        enableGlobalFilter: false,
        enableSorting: false,
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            {info.row.original.cashback ?
              <img alt='ticket' src={cashbackTito} className='max-h-[70px] w-auto'></img>
              :
              <img
                src={productUrl + info.row.original.photoUrl}
                alt="imagen"
                className='max-h-[90px] w-auto'
                onError={(e) => {
                  e.target.outerHTML = '<span class="text-neutral-500 text-xs">Imagen no disponible</span>';
                }}
              />
            }
          </div>
        ),
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            <Button
              variant='outline'
              onClick={() => deleteModal.handleClick(info.row.original.id)}
            >Cancelar
            </Button>
            {info.row.original.cashback ?
              <Button onClick={() => redeemCashbackModal.handleClick({id: info.row.original.id})}>
                Canjear
              </Button>
              :
              <Button onClick={() => redeemProductModal.handleClick({id: info.row.original.id})}>
                Canjear
              </Button>
            }
          </div>
        ),
      }
    ]
    , [deleteModal, redeemCashbackModal, redeemProductModal]
  );

  return (
    <div className='flex flex-col self-stretch flex-1 gap-4'>
      <BlockTitle title='CANJE DE PRODUCTOS' className='m-0'/>

      <div className='flex flex-col self-stretch gap-6 p-4 bg-neutral-800 rounded-2xl'>
        <ComboClient setSelectedCustomer={setSelectedCustomer} selectedCustomer={selectedCustomer}/>
      </div>
      {selectedCustomer !== null ?
        <Tabs defaultValue={activeTab} value={activeTab} onValueChange={setActiveTab}>
          <div className='flex flex-col self-stretch flex-1 p-4 bg-neutral-800 rounded-2xl'>
            <TabsList>
              <TabsTrigger value="productos" asChild>
                <div className={styles.customTabsTrigger}>
                  <LuShoppingCart size={24}/>
                  <span>Canjear</span>
                </div>
              </TabsTrigger>
              <TabsTrigger value="pendientes" asChild>
                <div className={styles.customTabsTrigger}>
                  <LuList size={24}/>
                  <span>Canjes pendientes</span>
                </div>
              </TabsTrigger>
            </TabsList>
            <TabsContent value="productos" className='mt-6'>
              {isLoading ?
                <BlockLoading/>
                :
                dataProducts?.products?.length ?
                  <div className='flex flex-col gap-4'>
                    <span className='font-semibold text-center uppercase'>Productos disponibles para canjear</span>
                    <div className='grid grid-cols-3 gap-4'>
                      {dataProducts.products.map(product => (
                        <div
                          className='flex flex-col items-center gap-4 p-4 text-center border cursor-pointer rounded-2xl border-neutral-600 hover:bg-neutral-600'
                          key={product.id}
                          onClick={() => {
                            setSelectedProduct(product);
                            setOpenConfirmDialog(true);
                          }}
                        >
                          <span className='text-lg font-semibold'>{product.name}</span>
                          <img src={productUrl + product.photoUrl} alt="imagen" className='max-h-[140px] w-auto'></img>
                          <div className='flex flex-row items-center gap-1.5 text-xl'>
                            <span>Stock:</span>
                            <strong>{product.stock}</strong>
                            {product.stock &&
                              <TbAlertCircleFilled size={22} className='text-amber-400 animate-bounce'/>
                            }
                          </div>
                          <span className='text-xl font-bold'>{getNameAndValue(product)}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  :
                  <EmptyState title='Sin resultados'
                              subtitle='No tienes los puntos suficientes para canjear un producto'/>
              }
            </TabsContent>
            <TabsContent value="pendientes" className='mt-6'>
              <div className='flex flex-col self-stretch gap-6'>
                {data && data.length > 0 ?
                  <ReactTable columns={columns} data={data?.filter(item => item.state === "GENERADO")}
                              initialPageSize={50}/>
                  :
                  <div className="relative w-full h-full overflow-hidden">
                    <EmptyState/>
                  </div>
                }
              </div>
            </TabsContent>
          </div>
        </Tabs>
        :
        <div className='flex flex-col self-stretch gap-6 p-4 bg-neutral-800 rounded-2xl'>
          <EmptyState
            title='Seleccione cliente'
            subtitle='Seleccione un cliente para ver los productos disponibles'
          />
        </div>
      }

      <deleteModal.ConfirmationModal/>
      <redeemCashbackModal.ConfirmationModal/>
      <redeemProductModal.ConfirmationModal/>
      <DialogConfirm
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={handleConfirmRedeem}
        disabled={cashbackRedeemMutation.isPending}
        body={
          <div className='flex flex-col gap-6 py-4'>
            <span>¿Estás seguro de que deseas canjear este producto?</span>
            <div className='flex flex-col items-center gap-4'>
              <span className='text-lg font-bold'>{selectedProduct?.name}</span>
              <img src={productUrl + selectedProduct?.photoUrl} alt="imagen" className='max-h-[140px] w-auto'></img>
            </div>
            <div className='flex flex-col gap-2 px-4'>
              <div className='flex flex-row items-center gap-4'>
                <span className='flex-1'>
                  {selectedProduct?.couponTypeId !== null ? 'Cupones disponibles' : 'Puntos disponibles'}
                </span>
                <strong className='text-right'>
                  {selectedProduct?.couponTypeId !== null
                    ? Math.floor(selectedProduct?.couponsQuantity)
                    : Math.floor(dataProducts?.customer?.pointsRedeemable)
                  }
                </strong>
              </div>
              <div className='flex flex-row items-center gap-4'>
                <span className='flex-1'>{selectedProduct?.name}</span>
                <strong className='text-right'>-{Math.floor(selectedProduct?.exchangeValue)}</strong>
              </div>
              <hr className='border-t-neutral-800'/>
              <div className='flex flex-row items-center gap-4'>
                <span className='flex-1'>Puntos restantes</span>
                <strong className='text-right'>{Math.floor(selectedProduct?.pointDifference)}</strong>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default CashBack
