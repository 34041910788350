import React, {useContext, useState} from 'react'
import {Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle, FormControl, Input, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '../../atoms'
import {useMutation, useQuery} from '@tanstack/react-query';
import {SiteContext} from '../../../context/SiteContext';
import axios from 'axios';
import {Apis} from '../../../utils/apis';
import {LuUser, LuUserPlus2} from 'react-icons/lu';
import {FiEdit} from 'react-icons/fi';
import {toast} from '../../../hooks/useToast';

const DialogAddClient = ({id}) => {

  const {modeLocal} = useContext(SiteContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const cloudEndpoint = Apis.CAJA + '/ticket-caja/'+id+'/add-customer';
  const localEndpoint = 'https://servidor.wargos.cloud/xticket/ticket-caja/'+id+'/add-customer';

  let defaultEndpoint = cloudEndpoint;
  if (modeLocal) {
    defaultEndpoint = localEndpoint;
  }

  console.log(defaultEndpoint);

  const {data: searchResults = [], refetch} = useQuery({
    queryKey: ['getCustomers', query],
    queryFn: async () => {
      if (query.length >= 3) {
        const response = await axios.get(`${Apis.SEARCH}/elastic?query=${query}`);
        return response.data;
      } else {
        return [];
      }
    }
  })

  const {mutate: addCustomer, isLoading} = useMutation({
    mutationFn: async () => {
      const response = await axios.post(defaultEndpoint, {
        customerId: selectedCustomer.id, // Mandas el id del cliente seleccionado
      });
      return response.data;
    },
    onSuccess: () => {
      setSelectedCustomer(null);
      setQuery('');
      setOpen(false); 
      toast({
        title: 'Cliente registrado',
        variant: 'success',
        duration: 1500
      });
      
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    },
  });

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
  };

  const handleChangeCustomer = () => {
    setSelectedCustomer(null);
    setQuery('');
    setOpen(true);
    refetch();
  };

  const handleCancel = () => {
    setSelectedCustomer(null);
    setQuery('');
    setOpen(false);
  };

  const handleDialogClose = (isOpen) => {
    if (!isOpen) {
      setSelectedCustomer(null);
      setQuery('');
    }
    setOpen(isOpen);
  };

  const handleSave = () => {
    if (selectedCustomer) {
      addCustomer();
    }
  };

  return (
    <>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
          <Button type='button' size='sm' className='w-8 h-8 px-0 text-white rounded-lg bg-neutral-900 hover:bg-neutral-800' onClick={() => setOpen(true)}>
            <LuUserPlus2 size={16} />
          </Button>
          </TooltipTrigger>
          <TooltipContent sideOffset={8} >
            <p>Agregar nombre del cliente al ticket</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      
      <Dialog open={open} onOpenChange={handleDialogClose}>
        <DialogOverlay />
        <DialogContent className='flex flex-col max-w-2xl gap-4'>
          <DialogHeader>
            <DialogTitle>Seleccione un cliente</DialogTitle>
            <DialogDescription>
              Ingrese al menos 3 letras de su documento o nombre
            </DialogDescription>
          </DialogHeader>
          <div className='flex flex-col gap-4'>
            {selectedCustomer === null ?
              <>
                <Input
                  type="text"
                  value={query}
                  onChange={handleInputChange}
                  placeholder="Buscar cliente..."
                />
                <div className='flex flex-col max-h-[300px] overflow-y-auto overflow-x-hidden'>
                  {searchResults.length > 0 ?
                    searchResults.map((customer) => (
                      <div
                        key={customer.id}
                        className='py-4 mx-4 text-lg border-b cursor-pointer border-neutral-500'
                        onClick={() => handleCustomerSelect(customer)}
                      >
                        {customer.fullTotalName}
                      </div>
                    ))
                    :
                    <div className="mx-4 text-base text-center text-neutral-500">
                      {query.length > 2 && searchResults.length === 0 && 'No hay resultados'}
                    </div>
                  }
                </div>
              </>
              :
              <FormControl>
                <div className='flex flex-row items-center mb-4'>
                  <div className='flex flex-row items-center flex-1 gap-2'>
                    <div className='flex items-center justify-center bg-white rounded-xl text-neutral-950 h-[50px] w-[50px]'>
                      <LuUser size={24} />
                    </div>
                    <div className='flex flex-col'>
                      <span className='text-lg font-bold'> {selectedCustomer?.fullTotalName}</span>
                      <span className='text-sm'> {selectedCustomer?.document}</span>
                    </div>
                  </div>
                  <Button variant='outline' size='sm' onClick={handleChangeCustomer}><FiEdit size={16} /> Cambiar</Button>
                </div>
                <div className='flex items-center justify-end gap-2'>
                  <Button variant='outline' onClick={handleCancel}>Cancelar</Button>
                  <Button onClick={handleSave} disabled={isLoading}>
                    {isLoading ? 'Guardando...' : 'Guardar'}
                  </Button>
                </div>
              </FormControl>
            }
          </div>
        </DialogContent>

      </Dialog>
    </>
  )
}

export default DialogAddClient
