import React, {useContext} from 'react'
import {cn} from '../../../utils/general'
import {SiteContext} from '../../../context/SiteContext';
import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../../utils/apis';

const TurnSelected = ({fondos}) => {

  const {fondo,setFondo} = useContext(SiteContext);

  const bankMutation = useMutation({
    mutationKey: ['op_get_banks'],
    mutationFn: (data) => axios.get(Apis.CAJA + '/bank', data),
    onSuccess: (response) => {
      localStorage.setItem('banks', JSON.stringify(response.data));
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const expensesCategoryMutation = useMutation({
    mutationKey: ['op_get_expenses_category'],
    mutationFn: (data) => axios.get(Apis.CAJA + '/expenses-category', data),
    onSuccess: (response) => {
      localStorage.setItem('expenses', JSON.stringify(response.data));
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const handleFondoSelection = (selectedFondo) => {
    setFondo(selectedFondo);
    localStorage.setItem('fondo', JSON.stringify(selectedFondo));
    bankMutation.mutate();
    expensesCategoryMutation.mutate();
  };

  return (
    <div className='flex flex-col gap-6'>
      <div className={cn('grid gap-4', fondos.length === 1 ? 'grid-cols-1' : 'grid-cols-2')}>
        {fondos.map((turn) => (
          <div
            key={turn.id}
            className={cn(
              'flex flex-col items-center justify-center gap-1 p-4 border cursor-pointer rounded-2xl hover:bg-neutral-900',
              fondo?.id === turn.id ? 'bg-white text-neutral-950 border-neutral-300  hover:bg-neutral-100' : 'border-neutral-500'
            )} onClick={() => handleFondoSelection(turn)}
          >
            <span className='text-lg font-bold'>{turn?.name}</span>
            <span className='text-sm text-neutral-400'>Periodo: {turn?.period}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TurnSelected