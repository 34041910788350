import {format, isValid, parseISO} from 'date-fns';
import {es} from 'date-fns/locale';
import React from 'react'
import {HiOutlineSlotMachine} from '../../../assets/icons/slot-machine';
import {TiArrowRightOutline} from 'react-icons/ti';
import {TbDeviceDesktopQuestion, TbDeviceDesktopUp} from "react-icons/tb";
import {cn} from '../../../utils/general';

const TicketDetails = ({ticketData}) => {

  const {ticketInfo, code: errorCode} = ticketData;

  const isValidDate = (date) => {
    const parsedDate = parseISO(date);
    return isValid(parsedDate);
  }

  const formatDate = (date) => {
    if (isValidDate(date)) {
      return format(parseISO(date), "dd MMM hh:mm a", {locale: es});
    }
    return '--';
  }

  const getSourceText = (ticketInfo) => {
    if (ticketInfo.machine){
      return ticketInfo.machine
    }

    if(ticketInfo.fondoName){
        return ticketInfo.fondoName
    }
    return "CAJA"
  }

  const getRedeemText = (ticketInfo) => {
    if (ticketInfo.confIdRedeem > 0){
      return ticketInfo.machineRedeem
    }

    return ticketInfo.fondoNameRedeem || "CAJA";
  }

  return (
    <div className='flex flex-row w-full gap-6'>
      <div className='flex flex-row items-center w-3/5 gap-4'>
        <div className='flex flex-row items-center flex-1 gap-2 p-4 border rounded-2xl border-neutral-600'>
          <div className='flex items-center justify-center w-14 h-14 bg-neutral-950 rounded-xl'>
          {ticketInfo.machine ? 
            <HiOutlineSlotMachine size={32} />
            :
            <TbDeviceDesktopUp size={32} />
          }
          </div>
          <div className='flex flex-col gap-1'>
            <strong className='text-xl'>
              {getSourceText(ticketInfo)}
            </strong>
            <span className='text-neutral-400'>{ticketInfo.emitido ? formatDate(ticketInfo.emitido) : '--'}</span>
          </div>
        </div>
        <TiArrowRightOutline size={42} className="slide-arrow"/>
        <div 
          className={
            cn('flex flex-row items-center flex-1 gap-2 p-4 border rounded-2xl border-neutral-600',
              ticketInfo.machineRedeem ? 'bg-red-600 text-white border-0':'',
          )}
        >
          <div className='flex items-center justify-center w-14 h-14 bg-neutral-950 rounded-xl'>
            {errorCode === 2 ?
              <TbDeviceDesktopQuestion size={32} />
              : ticketInfo.machineRedeem ?
                <HiOutlineSlotMachine size={32} />
                :
                <TbDeviceDesktopUp size={32} />
            }
          </div>
          <div className='flex flex-col gap-1'>
            <strong className='text-xl'>{errorCode === 2 ? '--': getRedeemText(ticketInfo)}</strong>
            <span className='text-neutral-400'>{ticketInfo.redeemAt ? formatDate(ticketInfo.redeemAt) : '--'}</span>
          </div>
        </div>
      </div>
      <div className='flex flex-col flex-1 w-2/5 gap-4'>
        <div
          className='flex flex-col items-center justify-center flex-1 gap-2 p-4 rounded-2xl bg-amber-200 text-neutral-950'>
          <span>Monto</span>
          <span className='text-3xl font-extrabold'>S/ {Number((ticketInfo?.monto ? ticketInfo?.monto : 0) * 0.01).toFixed(2)}</span>
        </div>
      </div>
    </div>
  )
}

export default TicketDetails
