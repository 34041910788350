import React, {forwardRef} from "react"
import {Slot} from "@radix-ui/react-slot"
import {cva} from "class-variance-authority"
import {cn} from "../../../utils/general"

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 text-base whitespace-nowrap rounded-2xl font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50  active:scale-[99%] active:translate-y-[2px] transition-transform duration-100",
  {
    variants: {
      variant: {
        default: "bg-amber-400 text-neutral-950 hover:bg-amber-400/90",
        outline:
          "border border-neutral-600 text-white bg-transparent hover:bg-white/10 hover:text-white",
        secondary: "bg-white text-neutral-950 hover:bg-white/90",
        ghost: "hover:bg-neutral-800 hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "px-4 h-[40px]",
        sm: "rounded-xl px-3 h-[32px] text-sm",
        lg: "rounded-2xl px-6 h-[50px] text-lg",
        icon: "rounded-2xl h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = forwardRef(
  ({className, variant, size, asChild = false, ...props}, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({variant, size, className}))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export {Button, buttonVariants};
