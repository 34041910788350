import React, {useContext, useState} from 'react'
import {Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '../../atoms'
import DialogConfirm from '../dialog-confirm';
import axios from 'axios';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {toast} from '../../../hooks/useToast';
import useEndpoint from '../../../hooks/useEndpoint';
import {SiteContext} from '../../../context/SiteContext';

const DialogRedeemTicket = ({ticketCode}) => {

  const [open, setOpen] = useState(false);
  const {fondo} = useContext(SiteContext);
  const redeemEndpoint = useEndpoint('redeemTicket');
  const queryClient = useQueryClient();

  const redeemTicketMutation = useMutation({
    mutationKey: ['redeem_manual_ticket'],
    mutationFn: async () => {
      const response = await axios.post(redeemEndpoint, {
        vn: ticketCode,
        fondoId: fondo.id,
        customerId: null
      })

      return response.data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: ['getTicketsSaleHistory']});
      if (data?.success) {
        toast({
          title: 'Ticket canjeado correctamente!',
          variant: 'success',
          duration: 2000
        })
      } else {
        toast({
          title: data?.message || 'Ha ocurrido un error al canjear el ticket.',
          variant: 'danger',
          duration: 2000
        })
      }
      setOpen(false)
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error al canjear el ticket';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleConfirm = () => {
    if (!ticketCode || !fondo?.id) {
      toast({
        title: 'No se puede canjear el ticket: faltan datos',
        variant: 'danger',
        duration: 2000
      })
      return
    }
    redeemTicketMutation.mutate()
  }

  return (
    <>
      <TooltipProvider>
        <Tooltip delayDuration={50}>
          <TooltipTrigger asChild>
            <Button
              type='button'
              size='sm'
              variant='secondary'
              className='h-7 text-[12px] rounded-lg'
              onClick={() => setOpen(true)}
            >
              Canjear
            </Button>
          </TooltipTrigger>
          <TooltipContent sideOffset={8} >
            <p>Canjear Ticket de caja</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogConfirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleConfirm}
        disabled={redeemTicketMutation.isPending}
        body={
          <div className='flex flex-col gap-6 py-4'>
            <span>¿Estás seguro de que deseas canjear este ticket?</span>
          </div>
        }
      />
    </>
  )
}

export default DialogRedeemTicket