import React, {useContext, useEffect, useRef, useState} from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  FormControl,
  Input
} from '../../components/atoms'
import styles from './operations.module.css';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {SiteContext} from '../../context/SiteContext';
import {toast} from '../../hooks/useToast';
import {LuCopy, LuInfo} from 'react-icons/lu';
import {formatNumber, formatShortDistanceToNow, getStatusIcon} from '../../utils/general';
import HisSaleTicket from "../historial/hisSaleTicket";
import {BlockTitle, DialogAddClient, SidebarHistory,TicketDispenser} from '../../components/blocks';
import {FaSpinner} from 'react-icons/fa';
import useEndpoint from "../../hooks/useEndpoint";

const SaleTicket = ({onFocusInput}) => {

  const {fondo, config, printer, modeLocal} = useContext(SiteContext);
  const saleEndpoint = useEndpoint('saleTicket');
  const hisSaleEndpoint = useEndpoint('hisSaleTicket');

  const [amount, setAmount] = useState('');
  const [validationType, setValidationType] = useState(0);
  const [open, setOpen] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  const {data} = useQuery({
    queryKey: ['getTicketsSale', fondo?.id],
    queryFn: () =>
      axios.get(hisSaleEndpoint, {
        params: {
          validOnly: false,
          fondoId: fondo?.id,
          promo: false,
          restricted: false
        }
      }).then((res) => res.data)
        .catch((err) => {
          console.error('Error fetching tickets sale:', err);
          throw err; // Es importante lanzar el error para que useQuery lo maneje
        }),
    enabled: !!fondo?.id,
    refetchInterval: 2000
  })

  useEffect(() => {
    if (onFocusInput) {
      onFocusInput(() => {
        inputRef.current?.focus();
      });
    }
  }, [onFocusInput]);

  const saleTicketMutation = useMutation({
    mutationKey: ['op_ticket_sale'],
    mutationFn: (data) => axios.post(saleEndpoint, data),
    onSuccess: () => {
      setPassword('');
      setShowPasswordInput(false);
      toast({
        title: "Operación realizada",
        variant: 'success',
        duration: 2000
      })
      inputRef.current.focus();
      queryClient.invalidateQueries(['getTicketsSale', fondo?.id]);
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (amount <= 0) {
      toast({
        title: 'El monto debe ser mayor a 0',
        variant: 'danger',
        duration: 2000
      });
      return;
    }

    if (amount > 50000) {
      toast({
        title: 'El monto es demasiado alto',
        variant: 'danger',
        duration: 2000
      });
      return;
    }

    if (validationType === 1 && !password) {
      setShowPasswordInput(true);
      return;
    } else if (validationType === 1 && password !== 'promo') {
      setErrorPassword('La contraseña es incorrecta');
      return;
    }

    if (amount) {
      const valueInCents = Math.round(parseFloat(amount) * 100);
      let finalValue = amount;
      if (modeLocal) {
        finalValue = valueInCents
      }

      inputRef.current.select();

      saleTicketMutation.mutate({
        amount: finalValue,
        amountCents: valueInCents,
        validationType: validationType,
        fondoId: fondo.id,
        computerId: printer?.computerId,
        printerId: printer?.printerId
      });
    }
  };

  const handleQuantitySelect = (item) => {
    setOpen(false);
  };

  const copyClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Copiado",
        variant: 'info',
        duration: 1500
      })
    });
  }

  return (
    <>
      <BlockTitle title='VENTA DE TICKET'/>

      <SidebarHistory
        title='Historial - Venta de tickets'
        className='mb-2'
      >
        <HisSaleTicket />
      </SidebarHistory>

      <div className='flex flex-col self-stretch flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>

        <form className='flex flex-col gap-6' onSubmit={handleSubmit}>
          <div className='flex flex-row items-start gap-4'>
            <div className='flex flex-col flex-1 gap-6'>
              <FormControl className='flex-1'>
                <label>Monto</label>
                <Input
                  placeholder="00.0"
                  type='number'
                  className='text-2xl font-semibold'
                  autoComplete="off"
                  ref={inputRef}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </FormControl>

              <FormControl className='flex-1'>
                <label>Tipo</label>
                <div className='flex flex-row items-center self-stretch flex-1 gap-4'>
                  <div
                    className={validationType === 0 ? styles.selectedButton : styles.buttonGroup}
                    onClick={() => setValidationType(0)}
                  >
                    Cobrable
                  </div>
                  <div
                    className={validationType === 1 ? styles.selectedButton : styles.buttonGroup}
                    onClick={() => setValidationType(1)}
                  >
                    Promo
                  </div>
                </div>
              </FormControl>

              <Button size='lg' type="submit" onClick={handleSubmit} disabled={saleTicketMutation.isPending}>
                {saleTicketMutation.isPending ?
                  <>
                    <FaSpinner className='animate-spin text-neutral-700' size={24}/>
                    <span className='text-neutral-700 animate-pulse'>Cargando...</span>
                  </>
                  :
                  <>
                    Vender
                    <span className='font-bold'>
                      {amount && amount !== '' && ('S/ ' + Number(amount).toFixed(2))}
                    </span>
                  </>
                }
              </Button>
            </div>
            <div className='flex flex-col flex-1 gap-2 max'>
              <div className='flex flex-row items-center justify-between'>
                <span className='text-sm'>Ultimos tickets vendidos</span>
                <TicketDispenser />
              </div>
              <div className='flex flex-col gap-4 px-4 text-sm'>
                {data?.data.length > 0 ? (
                  data.data.slice(0, 6).map((item) => (
                    <div key={item.id} className='flex flex-row items-center flex-1 gap-2'>
                      <div className='flex flex-row items-center flex-1 gap-2'>
                        {getStatusIcon(item.status)}
                        <span style={{fontFamily: '"Courier New", Courier, monospace'}}>{item.vnFull}</span>
                        <Button
                          size='sm'
                          variant='outline'
                          type='button'
                          className='w-8 h-8 px-0 rounded-lg'
                          onClick={() => copyClipboard(item.vnFull)}
                        >
                          <LuCopy size={14}/>
                        </Button>
                      </div>
                      <DialogAddClient id={item.ticketId}/>
                      <span className='flex-1 text-center'>
                        {formatShortDistanceToNow(item.createdAt)}
                      </span>
                      <span className='text-right'>{formatNumber(item.amount, config.symbol)}</span>
                    </div>
                  ))
                ) : (
                  <div className='flex flex-col items-center justify-center gap-2 py-4'>
                    <LuInfo size={32} className='text-amber-400'/>
                    <span className='text-center text-neutral-500'>Aún no se han realizado ventas de tickets</span>
                  </div>
                )}
              </div>
            </div>
          </div>

        </form>

        <Dialog
          open={showPasswordInput}
          onOpenChange={(isOpen) => {
            setShowPasswordInput(isOpen);
            if (!isOpen) {
              setErrorPassword('');
              setPassword('');
            }
          }}
        >
          <DialogOverlay/>
          <DialogContent className='flex flex-col gap-4'>
            <DialogHeader>
              <DialogTitle>Ticket Promocional</DialogTitle>
              <DialogDescription>
                Escribe <strong>"promo"</strong> para continuar
              </DialogDescription>
            </DialogHeader>
            <div className='flex flex-row items-center gap-2'>
              <span>Monto a vender:</span>
              <strong>{formatNumber(amount, config.symbol)}</strong>
            </div>
            <FormControl className='flex-1'>
              <Input
                type='password'
                placeholder='promo'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorPassword('');
                }}
                className='h-10 text-base placeholder:text-base'
              />
              {errorPassword && <span className="text-red-500">{errorPassword}</span>}
            </FormControl>
            <div className='flex flex-row items-center justify-end gap-2'>
              <Button
                variant='outline'
                onClick={() => {
                  setShowPasswordInput(false);
                  setErrorPassword('');
                  setPassword('');
                }}
              >
                Cancelar
              </Button>
              <Button onClick={handleSubmit}>Confirmar</Button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog open={open} onOpenChange={setOpen}>
          <DialogOverlay/>
          <DialogContent className='flex flex-col gap-4'>
            <DialogHeader>
              <DialogTitle>Seleccione cantidad</DialogTitle>
              <DialogDescription>
                Seleccione la cantidad que desee imprimir
              </DialogDescription>
            </DialogHeader>
            <div className='flex flex-col max-h-[300px] overflow-y-auto overflow-x-hidden'>
              {[1, 5, 10, 20].map((item, index) => (
                <div
                  key={index}
                  className='py-4 mx-4 text-lg border-b cursor-pointer border-neutral-500'
                  onClick={() => handleQuantitySelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>

      </div>
    </>
  )
}

export default SaleTicket
